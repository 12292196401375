<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="tag" :translate-params="{id: category.id, name: category.code}">Edit category  #%{id} - %{code}</translate>
    </h1>
    <TagCategoryForm :data="category" @save="onSave" :save_text="$pgettext('form','Save')"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";
import TagCategoryForm from "@/domain/tag/components/TagCategoryForm.vue";

export default {
  name: 'EditCategoryTag',
  components: {TagCategoryForm},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    try {
      await this.fetchCategory(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'tag_category.list'})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('tag', [
      'category',
    ]),
  },
  methods: {
    ...mapActions('tag', [
      'fetchCategory',
      'updateCategory',
    ]),
    async onSave(data) {
      await this.updateCategory({id: this.category.id,data: data})
      await this.$router.push({name: 'tag_category.list'})
      throw new SuccessNotification(this.$pgettext('tag', 'Tag Category updated!'))
    }
  }
}
</script>

<style scoped>

</style>